import { useContext, createContext, useMemo } from "react";
import type { AppTranslationDictionary } from "../localeTypes";
import { t } from "../dictionaries";
import { useLocaleContext } from "./LocaleProvider";

type ContextValue = AppTranslationDictionary;

// @ts-expect-error 確実に値を入れるので無視
const DictionaryContext = createContext<ContextValue>(undefined);

export const useDictionary = (): ContextValue => useContext(DictionaryContext);

export function DictionaryProvider({
  children,
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  const { locale } = useLocaleContext();

  const memoValue = useMemo(() => {
    const dict = t[locale];
    return dict;
  }, [locale]);

  return (
    <DictionaryContext.Provider value={memoValue}>
      {children}
    </DictionaryContext.Provider>
  );
}

// TODO: いずれクライアントサイドに辞書を送りたくない場合、もうUIに使う文字列や関数ごとpropsで渡す方式しかないかもな
