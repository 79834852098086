import { useContext, createContext, useMemo } from "react";
import type { Locales } from "../localeTypes";

type LocaleContextType = {
  locale: Locales;
};

const LocaleContext = createContext<LocaleContextType>({
  locale: "en",
});

export const useLocaleContext = (): LocaleContextType =>
  useContext(LocaleContext);

export function LocaleProvider({
  locale,
  children,
}: {
  readonly locale: Locales;
  readonly children: React.ReactNode;
}): JSX.Element {
  const memoValue = useMemo(() => {
    return {
      locale,
    };
  }, [locale]);

  return (
    <LocaleContext.Provider value={memoValue}>
      {children}
    </LocaleContext.Provider>
  );
}
