/* eslint-disable max-lines */
// NOTE: キーでをソートしたいのでこのファイルでだけそのルールを有効にする
/* eslint sort-keys: 2 */
import type { AppTranslationDictionary } from "../localeTypes";
import { APP_NAME } from "~/libs/APP_NAME";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";

export const englishAppDictionary: AppTranslationDictionary = {
  "404_next_steps": "Next steps",
  "404_page_may_have_been_removed": "The page may have been removed.",
  "404_the_page_you_are_looking_for_could_not_be_found":
    "The page you are looking for could not be found.",
  "404_url_maybe_incorrect": "The URL may be incorrect.",
  "account": "Account",
  "account_name": "Account Name",
  "add_a_chord_to_bottom_line": "Add a chord to the bottom line",
  "add_a_chord_to_the_current_bar": "Add a chord to the same bar",
  "add_a_chord_to_the_next_bar": "Add a chord to the next bar",
  "add_an_example_chord_progression": "Add an example chord progression",
  "add_new": "Add New",
  "add_to_x": (target: string) => `Add to ${target}`,
  "add_x": (target) => `Add ${target}`,
  "advanced_search_criteria": "Advanced Search Criteria",
  "agree_to_x": (target) => <>Agree to {target}</>,
  "agreement": "Agreement",
  "album": "Album",
  "albums": "Albums",
  "all": "All",
  "allow_crossing_next_section":
    "Include chord progressions that partially extend into the next section in the search",
  "already_registered_question": "Already registered?",
  "apply_filter": "Apply Filter",
  "approve": "Approve",
  "approved": "Approved",
  "artist": "Artist",
  "artist_name": "Artist Name",
  "as_a_free_user": "As a Free User",
  "as_a_premium_user": "As a Premium User",
  "average_momentum_of_chord_progression":
    "Average Momentum of Chord Progression",
  "awaiting_tabulation": "Awaiting Tabulation",
  "basic_information": "Basic Information",
  "beginning_with": "Beginning with",
  "cancel": "Cancel",
  "change_of_x": (target) => `Change of ${target}`,
  "change_request": "Change Request",
  "change_request_description":
    "If no action is taken within one week of receiving an edit request, it will be automatically approved. You can trace back to the original state from the Edit History tab on each chord progression detail page, so if you don't want to do that, please don't go through with it.",
  "change_x": (target) => `Change ${target}`,
  "chord": "Chord",
  "chord_progression_naming": "Chord Progression Naming",
  "chord_progressions": "Chord Progressions",
  "chord_type": "Chord Type",
  "clear": "Clear",
  "collection": "Collection",
  "comma": ", ",
  "comment": "Comment",
  "comments": "Comments",
  "confirm_email_description":
    "A confirmation email has been sent to you. Please click on the link in the email to complete your registration.",
  "confirm_password": "Confirm Password",
  "confirmation": "Confirmation",
  "contact": "Contact Us",
  "contact_page_description":
    "We are open to bug reports, questions, and anything else you may have",
  "contact_page_send_description":
    "The following information has been submitted.",
  "content": "Content",
  "copied_chord": "Copied chord",
  "copied_chord_description": "You can paste with ctrl(cmd) + v",
  "country": "Country",
  "create": "Create",
  "create_account_description": `Basic search and browsing is available without creating an account.
Once you have created an free account, you will be able to post and favorite content.
Premium users have access to more advanced search and private functions and a wider variety of themes.`,
  "create_x": (target, option) =>
    option?.withQuote ? `Create "${target}"` : `Create ${target}`,
  "created_x": (target) => `Created ${target}`,
  "current": "Current",
  "current_bar": "Current Bar",
  "current_x": (target) => `Current ${target}`,
  "degree": "Degree",
  "degree_progression": "Degree Progression",
  "delete": "Delete",
  "delete_confirmation_input_label": (confirmationText) => (
    <div>
      To delete account, enter {confirmationText} in the input field below, then
      click the button below.
    </div>
  ),
  "delete_reason_label":
    "Finally, if you have anything you would like to share with the management, please write to us!",
  "delete_x": (target) => `Delete ${target}`,
  "delete_x_question": (target) => `Delete ${target}?`,
  "description": "Description",
  "details": "Details",
  "diff": "Diff",
  "duplicate": "Duplicate",
  "duplicate_x": (target) => `Duplicate ${target}`,
  "edit": "Edit",
  "edit_details": "Edit Details",
  "edit_history": "Edit History",
  "edit_x": (target) => `Edit ${target}`,
  "email": "Email",
  "email_confirmation_advice1":
    "Please check for any input errors in your email address.",
  "email_confirmation_advice2":
    "Please check your spam folder or promotions tab.",
  "email_confirmation_advice3":
    "It may take a few minutes to be sent. If you do not receive it after a while, please try again.",
  "email_confirmation_message1":
    "A confirmation email has been sent to the following email address.",
  "email_confirmation_message2":
    "Please check your email and click on the link to complete the process.",
  "end_with": "End with",
  "exact_match": "Exact Match",
  "example_of_chord_progression": "Example of Chord Progression",
  "example_of_chord_progression_degree_notation":
    "Example of Chord Progression (Degree Notation)",
  "expansion_or_addition": "Expansion / Addition",
  "favorite": "Favorite",
  "filter_by_x": (target) => `Filter by ${target}`,
  "free_input": "Free Input",
  "frequently_used_artists_genres_etc": "Frequently Used Artists, Genres, etc.",
  "function": "Function",
  "functional_progression_graph_of_chord_progression":
    "Functional progression graph of chord progression",
  "fuzzy_match": "Fuzzy Match",
  "go_to_home_page": "Go to Home Page",
  "go_to_reason_entry": "Go to Reason Entry",
  "hello_world": "hello world",
  "help": "Help",
  "hero_description": (freeInputLabel: string) => (
    <>
      The website offers <span className="font-bold">powerful search</span> and{" "}
      <span className="font-bold">analysis</span> for{" "}
      <span className="font-bold">chord progressions</span> submitted by users.
      You can try various searches by pressing the &quot;{freeInputLabel}&quot;
      button in the search window at the top of the screen.
    </>
  ),
  "if_you_do_not_receive_an_email": "If you do not receive an email",
  "input_x": (target) => `Input ${target}`,
  "inputting": "(Inputting...)",
  "inter_functional_transition_rate_of_chord_progression":
    "Inter-Functional Transition Rate of Chord Progression",
  "key": "Key",
  "language": "Language",
  "legal_information": "Legal Information",
  "legal_notice": "Legal Notice",
  "list": "List",
  "list_of_user_curated_track_lists": "List of User-Curated Track Lists",
  "lists_containing_this_track": "Lists containing this track",
  "lists_containing_this_track_empty_description1":
    "There are no lists containing this track yet.",
  "lists_containing_this_track_empty_description2":
    "Would you like to add to list?",
  "loading": "Loading",
  "log_in_sign_in": "Log In / Sign In",
  "login": "Login",
  "login_password": "Login Password",
  "login_to_x": (target: string) => `Login to ${target}`,
  "logout": "Logout",
  "matching_tolerance": "Matching Tolerance",
  "move_down_section": "Move Down Section",
  "move_up_section": "Move Up Section",
  "multi_select": "Multi-Select",
  "my_page": "My Page",
  "name": "Name",
  "name_of_x": (target) => `the name of ${target}`,
  "name_the_chord_progressions_and_try_to_group_them":
    "Name the chord progressions and try to group them",
  "naming": "Naming",
  "new_line": "New Line",
  "new_line_supplement_recommendation":
    "Depending on the beat, a line break every 2~4 bars is recommended",
  "new_section": "New Section",
  "new_x": (target) => `New ${target}`,
  "newest": "Newest",
  "news": "News",
  "next_bar": "Next Bar",
  "no_data": "No Data",
  "no_list_has_been_created_yet": "No list has been created yet",
  "optional": "Optional",
  "or": "or",
  "other": "Other",
  "password": "Password",
  "password_validation_message_char_count":
    "Password must be at least 8 characters",
  "password_validation_message_char_special_character":
    "(Optional) Please include special characters (e.g. !@#$%^&*()_+-=)",
  "password_validation_message_confirmation_matched":
    "Please match the password with the confirmation password",
  "password_validation_message_single_byte_alphanumeric_characters_or_special_character":
    "Please enter only single-byte alphanumeric characters or special characters",
  "percentage_of_functions_used": "Percentage of Functions Used",
  "perpetual_license": "Perpetual License",
  "plan": "Plan",
  "play_link": "Play Link",
  "play_link_empty_description1":
    "There doesn't seem to be a playback link yet.",
  "play_link_empty_description2": "Would you suggest a link to YouTube, etc.?",
  "please_enter_a_comment": "Please enter a comment",
  "please_enter_a_reason_for_rejection": ({ minLength }) =>
    `Please enter a reason for rejection (${minLength} characters or more)`,
  "please_enter_search_criteria": "Please enter search criteria",
  "please_enter_up_to_n_characters": ({ maxLength }) =>
    `Up to ${maxLength} characters`,
  "please_provide_any_additional_information":
    "Please provide any additional information",
  "please_select_the_components_of_the_code":
    "Please select the components of the code",
  "please_select_the_components_of_the_code_description": (toneType) => {
    switch (toneType) {
      case "nashville": {
        return "(For example, if you want to input Cm(7)/G, select “C”, “m”, “7”, and “G”, respectively, from the left.)";
      }
      case "number_degree": {
        return "(For example, if you want to input 1m(7)/5, select “1”, “m”, “7”, and “5”, respectively, from the left.)";
      }
      default: {
        exhaustiveGuard(toneType);
        return "";
      }
    }
  },
  "please_select_the_function_of_the_code":
    "Please select the function of the code",
  "please_select_the_report_category": "Please select the report category",
  "post": "Post",
  "post_a_new_one_if_you_d_like": "Post a new one if you'd like.",
  "post_chord_progression": "Post Chord Progression",
  "post_x": (target: string) => `Post ${target}`,
  "posted_x": (target: string) => `Posted ${target}`,
  "premium": "Premium",
  "preview": "Preview",
  "privacy_policy": "Privacy Policy",
  "private": "Private",
  "proceed_to_upgrade_procedure": "Proceed to Upgrade Procedure",
  "profile": "Profile",
  "profile_description": "Profile Description",
  "public": "Public",
  "public_scope": "Public Scope",
  "reference_links": "Reference Links",
  "register": "Register",
  "register_a_new_album_name": "Register a New Album Name",
  "register_a_new_one_if_you_d_like": "Register a new one if you'd like.",
  "registration_is_almost_complete": "Registration is almost complete!",
  "reject": "Reject",
  "rejected": "Rejected",
  "reload_x": (target) => `Reload ${target}`,
  "remove_from_x": (target: string) => `Remove from ${target}`,
  "report": "Report",
  "report_category_copyright_violation": "Copyright Violation",
  "report_category_harassment": "Harassment",
  "report_category_hate_speech": "Hate Speech",
  "report_category_inappropriate_content": "Inappropriate Content",
  "report_category_misinformation": "Misinformation",
  "report_category_other": "Other",
  "report_category_spam": "Spam",
  "report_category_violence": "Violence",
  "request_to_add": "Request to Add",
  "request_to_add_x": (target) => `Request to add ${target}`,
  "request_to_change": "Request to Change",
  "request_to_delete_x": (target) => `Request to delete ${target}`,
  "root_note": "Root Note",
  "same_album_tracks": "Same Album Tracks",
  "same_artist_tracks": "Same Artist Tracks",
  "same_tag_tracks": "Same Tag Tracks",
  "save": "Save",
  "save_and_publish": "Save and Publish",
  "search": "Search",
  "search_by": "Search by",
  "search_by_chord_description": `Search for tracks by chord progression such as "C - Dm - G - C".
  This is useful when you want to find a song that completely overlaps with a specific chord progression, including the key.`,
  "search_by_chord_label": "Search by Chord",
  "search_by_degree_description": `Search for tracks based on chord progressions in degree notation, such as "1 - 2m - 5 - 1".
  This is useful for searches that are not tied to a specific key.`,
  "search_by_degree_label": "Search by Degree",
  "search_by_free_input_description":
    "You can search by artist name, album name, or song title.",
  "search_by_free_input_label": "Search by Free Input",
  "search_by_function_description": `Search for tracks by chord progression in functional notation such as "T - S - D - T".
  This is useful when you want to search for a song that is not tied to a specific key or degree.`,
  "search_by_function_label": "Search by Function",
  "search_chord_progressions_from_the_first_letter_of_artist_name":
    "Search chord progressions from the first letter of artist name",
  "search_filter": "Search Filter",
  "search_from_x": (target) => `Search from ${target}`,
  "search_history": "Search History",
  "search_results": "Search Results",
  "search_results_count_suffix": (num) => (num > 0 ? "results" : "result"),
  "search_results_of_x": (target) => `Search Results of ${target}`,
  "search_target_position": "Search Target Position",
  "search_target_section": "Search Target Section",
  "search_themes": "Search Themes",
  "section": "Section",
  "select_from_the_registered_album_names":
    "Select from the registered album names",
  "select_or_type_to_create": "Select or type to create",
  "select_the_list_to_which_you_want_to_add_this_track":
    "Select the list to which you want to add this track",
  "select_x": (target) => `Select ${target}`,
  "send": "Send",
  "send_edit_request": "Send Edit Request",
  "service_information": "Service Information",
  "settings": "Settings",
  "sign_up": "Sign Up",
  "similar_chord_tracks": "Similar Chord Tracks",
  "site_menu": "Site Menu",
  "slash_code": "Slash Code",
  "something_went_wrong": "Something went wrong",
  "specify": "Specify",
  "statistics_info": "Statistics Info",
  "statistics_info_description": "Independent calculation by the site",
  "statistics_uniqueness_of_the_entire_chord_progression":
    "Uniqueness of the entire chord progression",
  "submit": "Submit",
  "submitted_x": (target) => `Submitted ${target}`,
  "support": "Support",
  "tag": "Tag",
  "tag_empty_description1": "There are no tags yet.",
  "tag_empty_description2": "Would you like to add some?",
  "terms_of_service": "Terms of Service",
  "theme": "Theme",
  "time_days_ago": (days: number) => `${days} days ago`,
  "time_hours_ago": (hours: number) => `${hours} hours ago`,
  "time_minutes_ago": (minutes: number) => `${minutes} minutes ago`,
  "time_seconds_ago": (seconds: number) => `${seconds} seconds ago`,
  "title": "Title",
  "track_attributes": "Track Attributes",
  "track_details": "Track Details",
  "track_name": "Track Name",
  "unspecified": "Unspecified",
  "updates_may_be_delayed": "Updates may be delayed",
  "upgrade": "Upgrade",
  "upgrade_description1":
    "You can use advanced search functions that require some server-side processing.",
  "upgrade_description2": `You can perform chord progressions privately so that others cannot see them, and search for private posts. You can use ${APP_NAME} for your convenience only.`,
  "upgrade_description3":
    "You can freely choose from a wide range of color themes.",
  "upgrade_description4": `This is not a subscription. Once you purchase it, you can use the basic features of Premium that will be introduced in the future without any additional charge.`,
  "upgrade_label1": "Advanced Search Filter",
  "upgrade_label2": "Private Function",
  "upgrade_label3": "Rich Color Theme",
  "upgrade_label4": "Perpetual License",
  "upgrade_to_x": (target) => `Upgrade to ${target}`,
  "what_is_the_content": "What is the content?",
  "when_chord_is_unknown": "When the chord is unknown",
  "when_the_same_as_the_previous_code": "When the same as the previous code",
  "when_there_is_no_chord": "When there is no chord",
  "whole": "Whole",
  "with_brackets": (target: string) => `(${target})`,
  "within_the_same_country": "Within the same country",
  "x_is_invalid_format": (target: string) => `${target} is invalid format`,
  "x_is_required": (target: string) => `${target} is required`,
  "you_have_received_an_edit_request_for_your_post":
    "You have received an edit request for your post",
  "your_age": ({ age }: { age: number }) => `your age is ${age}`,
} as const;
