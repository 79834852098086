import type { DictionaryObject } from "../localeTypes";
import { japaneseAppDictionary } from "./ja";
import { englishAppDictionary } from "./en";

export const t = {
  ja: japaneseAppDictionary,
  en: englishAppDictionary,
} as const satisfies DictionaryObject;

// TODO: 無いキーを叩かれたら通知したい
