/* eslint-disable max-lines */
// NOTE: キーでをソートしたいのでこのファイルでだけそのルールを有効にする
/* eslint sort-keys: 2 */
import type { AppTranslationDictionary } from "../localeTypes";
// FIXME: APP_NAME、ここからimportしないでほしいかも。というかlinterつけてから対応かな
import { APP_NAME } from "~/libs/APP_NAME";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";

export const japaneseAppDictionary = {
  "404_next_steps": "次のステップ",
  "404_page_may_have_been_removed": "ページが削除された可能性があります。",
  "404_the_page_you_are_looking_for_could_not_be_found":
    "お探しのページが見つかりませんでした。",
  "404_url_maybe_incorrect": "URLが間違っている可能性があります。",
  "account": "アカウント",
  "account_name": "アカウント名",
  "add_a_chord_to_bottom_line": "下行にコードを追加",
  "add_a_chord_to_the_current_bar": "同じ小節にコードを追加",
  "add_a_chord_to_the_next_bar": "次の小節にコードを追加",
  "add_an_example_chord_progression": "コード進行の例を追加",
  "add_new": "新規追加",
  "add_to_x": (target: string) => `${target}に追加`,
  "add_x": (target: string) => `${target}を追加`,
  "advanced_search_criteria": "詳細検索条件",
  "agree_to_x": (target: JSX.Element) => <>{target}に同意する</>,
  "agreement": "同意",
  "album": "アルバム",
  "albums": "アルバム",
  "all": "全て",
  "allow_crossing_next_section":
    "次セクションに一部はみ出るコード進行も検索対象に含める",
  "already_registered_question": "すでに登録済みですか？",
  "apply_filter": "フィルターを適用",
  "approve": "承認",
  "approved": "承認しました",
  "artist": "アーティスト",
  "artist_name": "アーティスト名",
  "as_a_free_user": "無料ユーザーとして",
  "as_a_premium_user": "プレミアムユーザーとして",
  "average_momentum_of_chord_progression": "コード進行の平均運動量",
  "awaiting_tabulation": "集計待ち",
  "basic_information": "基本情報",
  "beginning_with": "で始まる",
  "cancel": "キャンセル",
  "change_of_x": (target: string) => `${target}の変更`,
  "change_request": "変更リクエスト",
  "change_request_description":
    "編集リクエストを受けてから1週間以内にアクションを取らない場合、自動的に承認されます。一応各コード進行ページの編集履歴タブから元の状態をたどることはできますので、それで別に良い場合はスルーしてください。",
  "change_x": (target: string) => `${target}を変更`,
  "chord": "コード",
  "chord_progression_naming": "コード進行ネーミング",
  "chord_progressions": "コード進行",
  "chord_type": "コードタイプ",
  "clear": "入力をクリア",
  "collection": "コレクション",
  "comma": "、",
  "comment": "コメント",
  "comments": "コメント",
  "confirm_email_description":
    "確認メールをお送りしました。メールに記載されたリンクをクリックして、登録を完了してください。",
  "confirm_password": "パスワード（確認のため再入力）",
  "confirmation": "確認",
  "contact": "お問い合わせ",
  "contact_page_description":
    "バグ報告やご質問、その他の内容など何でも受け付けています",
  "contact_page_send_description": "✔ 以下の内容で送信しました。",
  "content": "内容",
  "copied_chord": "コードをコピーしました",
  "copied_chord_description": "ctrl(cmd) + v でペーストできます",
  "country": "国",
  "create": "作成",
  "create_account_description": `アカウントを作成しなくても基本的な検索や閲覧はご利用いただけます。
フリーユーザーになると、各種投稿やお気に入り登録等ができるようになります。
プロユーザーはさらに高度な検索やプライベート機能、より豊富なテーマをご利用できるようになります。`,
  "create_x": (target: string, option) =>
    option?.withQuote ? `"${target}" を作成` : `${target}を作成`,
  "created_x": (target: string) => `作成した${target}`,
  "current": "現在",
  "current_bar": "同小節",
  "current_x": (target: string) => `現在の${target}`,
  "degree": "ディグリー",
  "degree_progression": "ディグリー進行",
  "delete": "削除",
  "delete_confirmation_input_label": (confirmationText) => (
    <div>
      削除するには以下の入力欄に {confirmationText}{" "}
      と入力した後、以下のボタンをクリックしてください。
    </div>
  ),
  "delete_reason_label":
    "最後に、何か運営に伝えたいことがあればぜひ書いてください！",
  "delete_x": (target: string) => `${target}を削除`,
  "delete_x_question": (target: string) => `${target}を削除しますか？`,
  "description": "説明",
  "details": "詳細情報",
  "diff": "差分",
  "duplicate": "複製",
  "duplicate_x": (target: string) => `${target}を複製`,
  "edit": "編集",
  "edit_details": "詳細情報を編集",
  "edit_history": "編集履歴",
  "edit_x": (target: string) => `${target}を編集`,
  "email": "メールアドレス",
  "email_confirmation_advice1":
    "メールアドレスの入力ミスがないかご確認ください。",
  "email_confirmation_advice2":
    "迷惑メールフォルダやプロモーションタブに振り分けられていないかご確認ください。",
  "email_confirmation_advice3":
    "送信されるまで数分かかる場合があります。しばらくお待ちいただいても届かない場合は、再度お試しください。",
  "email_confirmation_message1":
    "以下のメールアドレスに確認メールを送信しました。",
  "email_confirmation_message2":
    "メールを確認し、リンクをクリックして手続きを完了してください。",
  "end_with": "で終わる",
  "exact_match": "完全一致",
  "example_of_chord_progression": "コード進行例",
  "example_of_chord_progression_degree_notation":
    "コード進行例（ディグリー表記）",
  "expansion_or_addition": "拡張/追加",
  "favorite": "お気に入り",
  "filter_by_x": (target: string) => `${target}で絞り込む`,
  "free_input": "自由入力",
  "frequently_used_artists_genres_etc": "よく使われるアーティスト・ジャンル等",
  "function": "機能",
  "functional_progression_graph_of_chord_progression":
    "コード進行の機能進行グラフ",
  "fuzzy_match": "類似一致",
  "go_to_home_page": "ホームページへ",
  "go_to_reason_entry": "理由の入力へ",
  "hello_world": "こんにちワールド",
  "help": "ヘルプ",
  "hero_description": (freeInputLabel: string) => (
    <>
      ユーザーから投稿された<span className="font-bold">コード進行</span>
      に対して<span className="font-bold">強力な検索</span>と
      <span className="font-bold">分析</span>
      ができるウェブサイト。画面上部の検索窓の「
      {freeInputLabel}」ボタンを押して様々な検索をお試しできます。
    </>
  ),
  "if_you_do_not_receive_an_email": "メールが届かない場合",
  "input_x": (target: string) => `${target}を入力`,
  "inputting": "（入力中...）",
  "inter_functional_transition_rate_of_chord_progression":
    "コード進行の機能間遷移率",
  "key": "キー",
  "language": "言語",
  "legal_information": "法的情報",
  "legal_notice": "特定商取引法に基づく表示",
  "list": "リスト",
  "list_of_user_curated_track_lists":
    "ユーザーがキュレーションしたトラックリスト一覧",
  "lists_containing_this_track": "この曲を含むリスト",
  "lists_containing_this_track_empty_description1":
    "まだこの曲を含むリストがありません。",
  "lists_containing_this_track_empty_description2": "リストに追加しますか？",
  "loading": "読み込み中",
  "log_in_sign_in": "ログイン / 新規登録",
  "login": "ログイン",
  "login_password": "ログインパスワード",
  "login_to_x": (target: string) => `${target}にログイン`,
  "logout": "ログアウト",
  "matching_tolerance": "許容一致度",
  "move_down_section": "セクションを下に移動",
  "move_up_section": "セクションを上に移動",
  "multi_select": "複数選択可能",
  "my_page": "マイページ",
  "name": "名前",
  "name_of_x": (target: string) => `${target}の名前`,
  "name_the_chord_progressions_and_try_to_group_them":
    "コード進行を名前を付け、グルーピングを試みましょう",
  "naming": "ネーミング",
  "new_line": "新しい行",
  "new_line_supplement_recommendation":
    "拍子にもよりますが、2~4小節ごとに改行することを推奨",
  "new_section": "新しいセクション",
  "new_x": (target: string) => `新しい${target}`,
  "newest": "新着",
  "news": "ニュース",
  "next_bar": "次の小節",
  "no_data": "データがありません",
  "no_list_has_been_created_yet": "まだリストは作成されていません",
  "optional": "任意",
  "or": "または",
  "other": "その他",
  "password": "パスワード",
  "password_validation_message_char_count":
    "パスワードは最低8文字にしてください",
  "password_validation_message_char_special_character":
    "（任意）半角記号を含めてください（例：!@#$%^&*()_+-=）",
  "password_validation_message_confirmation_matched":
    "「新しいパスワード」と「パスワード（確認のため再入力）」を一致させてください",
  "password_validation_message_single_byte_alphanumeric_characters_or_special_character":
    "半角英数字または記号のみで入力してください",
  "percentage_of_functions_used": "使用機能の割合",
  "perpetual_license": "永久ライセンス",
  "plan": "プラン",
  "play_link": "再生リンク",
  "play_link_empty_description1": "まだ再生リンクが無いようです。",
  "play_link_empty_description2": "YouTubeなどのリンクを提案しますか？",
  "please_enter_a_comment": "コメントを入力してください",
  "please_enter_a_reason_for_rejection": ({ minLength }) =>
    `却下理由を入力してください（${minLength}文字以上）`,
  "please_enter_search_criteria": "検索条件を入力してください",
  "please_enter_up_to_n_characters": ({ maxLength }) => `最大${maxLength}文字`,
  "please_provide_any_additional_information": "補足があれば記入してください",
  "please_select_the_components_of_the_code":
    "コードの構成要素を選択してください",
  "please_select_the_components_of_the_code_description": (toneType) => {
    switch (toneType) {
      case "nashville": {
        return "（操作説明：例えば Cm(7)/G を入力したい場合、左から「C」「m」「7」「G」をそれぞれ選択してください。）";
      }
      case "number_degree": {
        return "（操作説明：例えば Cm(7)/G を入力したい場合、左から「1」「m」「7」「5」をそれぞれ選択してください。）";
      }
      default: {
        exhaustiveGuard(toneType);
        return "";
      }
    }
  },
  "please_select_the_function_of_the_code": "コードの機能を選択してください",
  "please_select_the_report_category": "報告カテゴリを選択してください",
  "post": "投稿",
  "post_a_new_one_if_you_d_like": "新規投稿する場合はこちら",
  "post_chord_progression": "コード進行を投稿",
  "post_x": (target: string) => `${target}を投稿`,
  "posted_x": (target: string) => `投稿した${target}`,
  "premium": "プレミアム",
  "preview": "プレビュー",
  "privacy_policy": "プライバシーポリシー",
  "private": "非公開",
  "proceed_to_upgrade_procedure": "アップグレード手続きに進む",
  "profile": "プロフィール",
  "profile_description": "プロフィールの説明文",
  "public": "公開",
  "public_scope": "公開範囲",
  "reference_links": "参考リンク",
  "register": "登録",
  "register_a_new_album_name": "新しいアルバム名を登録",
  "register_a_new_one_if_you_d_like": "新規登録する場合はこちら",
  "registration_is_almost_complete": "登録完了まであと少しです！",
  "reject": "却下",
  "rejected": "却下しました",
  "reload_x": (target) => `${target}を再読み込み`,
  "remove_from_x": (target: string) => `${target}から削除`,
  "report": "報告",
  "report_category_copyright_violation": "著作権侵害",
  "report_category_harassment": "嫌がらせ",
  "report_category_hate_speech": "ヘイトスピーチ",
  "report_category_inappropriate_content": "不適切なコンテンツ",
  "report_category_misinformation": "偽情報",
  "report_category_other": "その他",
  "report_category_spam": "スパム",
  "report_category_violence": "暴力的内容",
  "request_to_add": "追加リクエスト",
  "request_to_add_x": (target) => `${target}の追加をリクエスト`,
  "request_to_change": "変更をリクエスト",
  "request_to_delete_x": (target) => `${target}の削除をリクエスト`,
  "root_note": "コードのルート",
  "same_album_tracks": "同アルバムの曲",
  "same_artist_tracks": "同アーティストの曲",
  "same_tag_tracks": "同タグの曲",
  "save": "保存",
  "save_and_publish": "保存して公開",
  "search": "検索",
  "search_by": "検索 by",
  "search_by_chord_description": `"C - Dm - G - C"等のコード進行から曲を検索します。
  キーも含め、特定のコード進行と完全にかぶる曲を見つけたい時に有用です。`,
  "search_by_chord_label": "コードで検索",
  "search_by_degree_description": `"1 - 2m - 5 - 1"等のディグリー表記のコード進行から曲を検索します。
  特定のキーに縛られない検索をしたい時に有用です。`,
  "search_by_degree_label": "ディグリーで検索",
  "search_by_free_input_description": `アーティスト名、アルバム名、曲名から検索できます。`,
  "search_by_free_input_label": "自由入力で検索",
  "search_by_function_description": `"T - S - D - T"等の機能表記のコード進行から曲を検索します。
  特定のキーやディグリーに縛られない検索をしたい時に有用です。`,
  "search_by_function_label": "機能で検索",
  "search_chord_progressions_from_the_first_letter_of_artist_name":
    "アーティスト名の頭文字からコード進行を検索",
  "search_filter": "検索フィルター",
  "search_from_x": (target: string) => `${target}から検索`,
  "search_history": "検索履歴",
  "search_results": "検索結果",
  "search_results_count_suffix": () => "件",
  "search_results_of_x": (target: string) => `${target}の検索結果`,
  "search_target_position": "検索対象位置",
  "search_target_section": "検索対象セクション",
  "search_themes": "テーマを検索",
  "section": "セクション",
  "select_from_the_registered_album_names": "登録済みのアルバム名から選択",
  "select_or_type_to_create": "選択 または 入力して作成",
  "select_the_list_to_which_you_want_to_add_this_track":
    "この曲を追加するリストを選択",
  "select_x": (target: string) => `${target}を選択`,
  "send": "送信",
  "send_edit_request": "編集リクエストを送信",
  "service_information": "サービス情報",
  "settings": "設定",
  "sign_up": "新規登録",
  "similar_chord_tracks": "似たコードの曲",
  "site_menu": "サイトメニュー",
  "slash_code": "スラッシュコード",
  "something_went_wrong": "エラーが発生しました",
  "specify": "指定",
  "statistics_info": "統計情報",
  "statistics_info_description": "サイトによる独自算出",
  "statistics_uniqueness_of_the_entire_chord_progression":
    "コード進行全体のユニーク性",
  "submit": "確定",
  "submitted_x": (target: string) => `投稿した${target}`,
  "support": "サポート",
  "tag": "タグ",
  "tag_empty_description1": "まだタグがありません。",
  "tag_empty_description2": "追加しますか？",
  "terms_of_service": "利用規約",
  "theme": "テーマ",
  "time_days_ago": (days: number) => `${days}日前`,
  "time_hours_ago": (hours: number) => `${hours}時間前`,
  "time_minutes_ago": (minutes: number) => `${minutes}分前`,
  "time_seconds_ago": (seconds: number) => `${seconds}秒前`,
  "title": "タイトル",
  "track_attributes": "曲の属性",
  "track_details": "曲の詳細情報",
  "track_name": "曲名",
  "unspecified": "無指定",
  "updates_may_be_delayed": "更新が遅れる場合があります",
  "upgrade": "アップグレード",
  "upgrade_description1":
    "多少サーバー側の処理にコストがかかるような高度な検索機能が使えるようになります。",
  "upgrade_description2": `コード進行を他の人に見は見えないように非公開で行い、非公開な投稿を含めた検索ができます。${APP_NAME}を自分だけで便利に使えます。`,
  "upgrade_description3":
    "幅広いカラーテーマから自由に選択できるようになります。",
  "upgrade_description4":
    "サブスクリプションではありません。一度購入いただけば、将来的に導入されるPremium専用の基本機能は追加料金無しでご利用できます。",
  "upgrade_label1": "高度な検索フィルター",
  "upgrade_label2": "プライベート機能",
  "upgrade_label3": "豊富なカラーテーマ",
  "upgrade_label4": "永久ライセンス",
  "upgrade_to_x": (target: string) => `${target}にアップグレード`,
  "what_is_the_content": "どのような内容ですか？",
  "when_chord_is_unknown": "コードが不明の場合",
  "when_the_same_as_the_previous_code": "前のコードと同じ場合",
  "when_there_is_no_chord": "コードがない場合",
  "whole": "全体",
  "with_brackets": (target: string) => `（${target}）`,
  "within_the_same_country": "同国内",
  "x_is_invalid_format": (target: string) => `${target}の形式が不正です`,
  "x_is_required": (target: string) => `${target}は必須です`,
  "you_have_received_an_edit_request_for_your_post":
    "あなたの投稿に編集リクエストが届いています",
  "your_age": ({ age }: { age: number }) => `あなたの年齢は${age}歳です`,
} as const satisfies AppTranslationDictionary;
